@font-face {
  font-family: 'AeonikProReqular';
  src:
    local('AeonikProReqular'),
    url(https://assets.website-files.com/60048f7343094628c841c251/600492be1573b24ba36d1938_AeonikPro-Regular.woff2)
      format('woff');
}

@font-face {
  font-family: 'TiemposTextRegular';
  src:
    local('TiemposTextRegular'),
    url(https://assets.website-files.com/60048f7343094628c841c251/600492de0d04ad1dd94d5b68_tiempos-text-web-regular.woff2)
      format('woff');
}

body {
  overflow-y: hidden;
}

main {
  overflow-x: hidden;
}

a {
  color: black;
  text-decoration: none;
  background-color: transparent;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#big-img {
  max-width: 90%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
}

.lazy {
  background-image: none !important;
  background-color: #242424;
}

.social-share-icons {
  padding: 10px;
}

.social-share-icons button {
  margin-right: 5px;
}

.carousel .slide {
  background-color: initial !important;
}

.image-gallery-svg {
  height: 50px !important;
}

.image-gallery-image {
  width: 100%;
  height: 36vh !important;
  object-fit: cover !important;
  border-radius: 12px;
}

.image-gallery-description {
  top: 8px;
  left: 8px;
  bottom: auto;
  height: fit-content !important;
  padding: 4px 6px !important;
  font-size: 13px;
  font-family: 'AeonikProReqular', sans-serif;
  margin-left: 10px !important;
  border-radius: 6px;
  text-transform: uppercase;
  background-color: #000000;
}

.ck {
  border-radius: 10px !important;
}
.ck-reset_all {
  display: none;
}
.ck-editor__editable {
  min-height: 150px;
}

button:focus {
  outline: none !important;
}

.MuiBadge-colorError {
  background-color: #ff432e !important;
}

.firebaseui-idp-google > .firebaseui-idp-text {
  color: #ffffff !important;
}
.firebaseui-list-item > button {
  border-radius: 20px;
}

.firebaseui-link {
  color: #000000 !important;
}

.text-uppercase {
  text-transform: capitalize;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 1px solid #000000 !important;
}

.image-gallery-thumbnail {
  width: 100px;
}

.image-gallery-thumbnail img {
  width: auto;
  height: 75px;
}

.image-gallery-slide img {
  width: 100%;
  height: 200px;
}

.MuiAlert-filledSuccess {
  background-color: #15a05b !important;
}
.MuiAlert-filledError {
  background-color: #da1c06 !important;
}
.MuiAlert-filledInfo {
  background-color: #a373e8 !important;
}

.mdl-button--primary.mdl-button--primary {
  color: #000000 !important;
}

.mdl-button--raised.mdl-button--colored {
  background-color: #000000 !important;
  color: #ffffff !important;
  border-radius: 21px !important;
}

.mdl-button--raised.mdl-button--colored:hover,
.mdl-button--raised.mdl-button--colored:active,
.mdl-button--raised.mdl-button--colored:focus {
  background-color: #e6e6e6 !important;
  color: #000000 !important;
  border-radius: 21px !important;
}

.mdl-button--raised.mdl-button--colored:focus:not(:active) {
  background-color: #e6e6e6 !important;
}

.primary-button-extra-small {
  font-size: 10px !important;
  padding: 0 12px !important;
  height: 24px !important;
  border-radius: 50px !important;
  background-color: #e6e6e6 !important;
  color: #000000;
}
.primary-button-extra-small:hover {
  background-color: #000000 !important;
  color: #ffffff !important;
}
.primary-button-extra-small:disabled {
  color: rgba(0, 0, 0, 0.25) !important;
}

.primary-button-small {
  font-size: 12px !important;
  padding: 0 14px !important;
  height: 28px !important;
  border-radius: 50px !important;
  background-color: #e6e6e6 !important;
  color: #000000;
}
.primary-button-small:hover {
  background-color: #000000 !important;
  color: #ffffff !important;
}
.primary-button-small:disabled {
  color: rgba(0, 0, 0, 0.25) !important;
}

.primary-button {
  font-size: 14px !important;
  padding: 0 16px !important;
  height: 32px !important;
  border-radius: 50px !important;
  background-color: #e6e6e6 !important;
  color: #000000;
}
.primary-button:hover {
  background-color: #000000 !important;
  color: #ffffff !important;
}
.primary-button:disabled {
  color: rgba(0, 0, 0, 0.25) !important;
}

.primary-button-large {
  font-size: 16px !important;
  padding: 0 18px !important;
  height: 36px !important;
  border-radius: 50px !important;
  background-color: #e6e6e6 !important;
  color: #000000;
}
.primary-button-large:hover {
  background-color: #000000 !important;
  color: #ffffff !important;
}
.primary-button-large:disabled {
  color: rgba(0, 0, 0, 0.25) !important;
}

.secondary-button {
  min-width: fit-content !important;
  padding: 0 !important;
  border-radius: 0 !important;
  line-height: 16px !important;
  color: #000000 !important;
  border-bottom: 1px solid #000000 !important;
}
.secondary-button:hover {
  color: rgba(0, 0, 0, 0.54) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.54) !important;
}
.secondary-button:disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.bg-theme-blue {
  background-color: #22abf7 !important;
}

.bg-theme-green {
  background-color: #15a05b !important;
}

.rounded-textfield {
  width: 100%;
  height: 36px;
}
.rounded-textfield fieldset {
  border-radius: 22px !important;
}
.rounded-textfield input {
  background-color: #e6e6e6;
  padding-top: 9px;
  padding-bottom: 9px;
  font-family: 'AeonikProReqular', sans-serif;
}

.MuiSnackbar-anchorOriginTopCenter {
  top: 56px !important;
  z-index: 21000;
}

.mdl-shadow--2dp {
  box-shadow: unset !important;
}

.chatlio-widget .chatlio-title-bar,
.chatlio-widget .chatlio-title-bar-button,
.chatlio-widget .chatlio-title-bar-chip {
  margin: 0 !important;
}

.chatlio-widget.chatlio-closed .chatlio-title-bar.chatlio-title-bar-button,
.chatlio-widget.chatlio-closed .chatlio-title-bar.chatlio-title-bar-chip {
  margin: 0 !important;
}

.MuiLinearProgress-bar1Indeterminate {
  width: auto;
  animation: MuiLinearProgress-keyframes-indeterminate0 4.1s
    cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.MuiAutocomplete-option {
  font-family: 'AeonikProReqular', sans-serif;
}

.google-places-autocomplete__input {
  -webkit-appearance: none;
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  border: 2px solid black;
}
.ck-focused {
  border: 2px solid black !important;
}

.redacted-text {
  position: relative;
  white-space: pre;
  background: black;
  border-radius: 0.1em;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.35);
  content: ' ';
  width: 100%;
  height: 1.2em;
  transform: skewY(-5deg) rotate(5deg);
}

.anchor:hover {
  text-decoration: none;
}

input:autofill {
  padding: 0px 12px !important;
  border-radius: 22px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bounce {
  animation: bounce 2s ease infinite;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-12px);
  }
  60% {
    transform: translateY(-6px);
  }
}

.gelatine {
  animation: gelatine 1.5s infinite;
}
@keyframes gelatine {
  from,
  to {
    transform: scale(1, 1);
  }
  15% {
    transform: scale(0.9, 1.1);
  }
  25% {
    transform: scale(1.1, 0.9);
  }
  35% {
    transform: scale(0.95, 1.05);
  }
  50% {
    transform: scale(1, 1);
  }
}

.shake {
  animation: shake 2s ease infinite;
}
@keyframes shake {
  0%,
  60% {
    transform: translateX(0);
  }
  15%,
  25%,
  35%,
  45% {
    transform: translateX(-3px);
  }
  20%,
  30%,
  40%,
  50% {
    transform: translateX(3px);
  }
}

input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select:focus,
textarea {
  font-size: 16px;
}

.signature-pad-container {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid black;
  border-radius: 8px;
  margin-bottom: 10px;
  width: 400px;
  height: 200px;
}
